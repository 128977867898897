import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  courierUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import {
  useRouter, useRoute
} from 'vue-router'
import lodash from 'lodash'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import moment from 'moment'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const dataCourier = ref([]) as any
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const filter = {
      offset: 0,
      top: $numbers.totalItemPerPage,
      skip: 1,
      expand: '&$expand=kendaraans($select=PlatNomor,KurirMsUserId,Roda,IsActive),KurirMsUserDocuments($select=KurirMsUserId,Jenis,Path)'
    }
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const dataForm = ref(null)
    const op = ref()
    const route = useRoute()
    const indexData = ref(null)
    const agentId = ref('')
    const agentCityGroupId = ref('')
    const roleUserId = ref(null) as any

    const getAgent = async () => {
      store.dispatch('showLoading')
      agentId.value = store.getters['appActiveUser/getAgent']?.id
      agentCityGroupId.value = store.getters['appActiveUser/getAgent']?.cityGroupId
      roleUserId.value = store.getters['appActiveUser/getAppActiveUser']?.roleId
      console.log('roleUserId', sendInputSearch.value === '')
      const response = await courierUseCase.getMinimalInfo({
        PageNumber: `?PageNumber=${filter.skip}`,
        PageSize: `&PageSize=${filter.top}`,
        // AgenId: `&AgenId=${agentId.value}`,
        GroupCityId: roleUserId.value !== 1 ? `&GroupCityId=${agentCityGroupId.value}` : '',
        KurirName: sendInputSearch.value !== '' ? `&KurirName=${sendInputSearch.value.toLowerCase()}` : ''
      })
      totalRecords.value = response.result.Count
      dataCourier.value = response.result.Data
      store.dispatch('hideLoading')
    }
    const onPage = (event: any) => {
      filter.skip = event.page + 1
      getAgent()
    }
    const searchData = async (val: any) => {
      sendInputSearch.value = val ? val.toLowerCase() : val
      // console.log('search Data', sendInputSearch.value)
      await getAgent()
    }
    const redirectAdd = () => {
      router.push({
        name: 'master-courier-tambah'
      })
    }
    const showDropDown = (evt: any, data: any, index: any) => {
      // console.log('masuk click drop', data, evt)
      indexData.value = index
      dataForm.value = data
      op.value.toggle(evt)
    }
    const editData = (data: any) => {
      // console.log('data edit', router)
      const dataEnc = encryptDecriptMethods.encrypt(data.Id)
      router.push({
        name: 'master-courier-edit',
        params: {
          courierId: dataEnc
        }
      })
    }
    const trackingFormCourier = () => {
      // console.log('data edit', router)
      // console.log('data editData', data)
      // const IdCourier = data.Id
      router.push({
        name: 'master-courier-tracking',
        // params: {
        //   courierId: IdCourier
        // }
      })
    }

    /** router push untuk ke detail data */
    const detailData = (data: any) => {
      const dataEnc = encryptDecriptMethods.encrypt(data.Id)
      router.push({
        name: 'master-courier-detail',
        params: {
          courierId: dataEnc
        }
      })
    }

    /** Konfirmasi untuk delete data Kurir */
    const deleteData = (Id: number) => {
      $confirm.require({
        header: 'Kurir',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierUseCase.deleteData(Id)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            getAgent()
          }
        }
      })
    }

    /** Sending ke api untuk IsActive kurir */
    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const response = await courierUseCase.changeIsActive({
        Id: val.Id,
        IsActive: val.IsActive
      })

      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    /** Untuk menyambungkan dengan api fungsi verifikasi */
    const isVerified = async (val: any) => {
      console.log('masuk paling atas')
      store.dispatch('showLoading')
      courierUseCase.changeIsVerified({
        Id: val.Id,
        IsVerified: true
      }).then((response: any) => {
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        } else {
          val.IsVerified = !val.IsVerified
          $toast.add({
            severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
          })
        }
        store.dispatch('hideLoading')
      })
    }

    /** Fungsi untuk perubahan dengan konfirmasi IsActive kurir */
    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: 'Apakah anda ingin menggantinya?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    /** Fungsi untuk perubahan dengan konfirmasi verifikasi  */
    const changeIsVerified = (val: any) => {
      if (val.IsVerified) {
        $toast.add({
          severity: 'error', detail: 'Gagal, data sudah diverifikasi', group: 'bc', life: 1500
        })
      } else {
        $confirm.require({
          header: 'Verifikasi',
          message: 'Apakah anda ingin melakukan verifikasi data kurir?',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            isVerified(val)
          },
          // reject: () => {
          //   val.IsVerified = !val.IsVerified
          // }
        })
      }
    }

    const resetKurir = (username: any) => {
      console.log('test', username)
      $confirm.require({
        header: 'Atur Ulang Perangkat',
        message: 'Apakah anda yakin ingin mengatur ulang perangkat?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierUseCase.resetDevice(username)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            getAgent()
          }
        }
      })
    }

    const openForm = ref(false)

    onMounted(() => {
      console.log('store', store.state)
      getAgent()
      openForm.value = false
    })
    return {
      dataCourier,
      getAgent,
      filter,
      store,
      totalRecords,
      onPage,
      sliderActive,
      inputSearch,
      sendInputSearch,
      searchData,
      router,
      redirectAdd,
      dataForm,
      op,
      showDropDown,
      editData,
      deleteData,
      changeIsActive,
      app,
      route,
      lodash,
      openForm,
      trackingFormCourier,
      detailData,
      changeIsVerified,
      indexData,
      moment,
      resetKurir
    }
  }
}
